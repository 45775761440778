import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { isInIsrael } from '../../utils/geolocation';
import { FaFacebook, FaLinkedin } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background: ${props => props.theme.colors.background};
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 4rem 2rem;
  color: ${props => props.theme.colors.text};
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    gap: 4rem;
  }
`;

const FooterSection = styled.div`
  h3 {
    color: ${props => props.theme.colors.primary};
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
    opacity: 0.8;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 0.95rem;
    }
  }
`;

const LocationItem = styled.div`
  margin-bottom: 2rem;
`;

const LocationText = styled.h4`
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 0.5rem;
  font-size: 1.1rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 1rem;
  }
`;

const EmailLink = styled.a`
  opacity: 0.8;
  line-height: 1.6;
  font-size: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 0.95rem;
  }
`;

const FooterLinks = styled.ul`
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 0.8rem;
  }

  a {
    color: ${props => props.theme.colors.text};
    text-decoration: none;
    opacity: 0.8;
    transition: all 0.3s ease;
    font-size: 1rem;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 0.95rem;
    }

    &:hover {
      color: ${props => props.theme.colors.primary};
      opacity: 1;
    }
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const SocialIcon = styled(motion.a)`
  color: ${props => props.theme.colors.text};
  font-size: 1.5rem;
  opacity: 0.8;
  transition: all 0.3s ease;

  &:hover {
    color: ${props => props.theme.colors.primary};
    opacity: 1;
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  opacity: 0.8;
  font-size: 0.9rem;
`;

const locations = [
  {
    city: 'London, UK'
  },
  {
    city: 'Melbourne, Australia'
  },
  {
    city: 'Yavne, Israel'
  }
];

const Footer: React.FC = () => {
  const [showIsraelOption, setShowIsraelOption] = useState(false);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const checkLocation = async () => {
      const inIsrael = await isInIsrael();
      setShowIsraelOption(inIsrael);
    };
    checkLocation();
  }, []);

  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>About NeonPixel</h3>
          <p>We create stunning digital experiences that transform businesses and engage audiences. With offices in {showIsraelOption ? 'Yavne, ' : ''}London and Melbourne, we serve clients globally.</p>
          <SocialLinks>
            <SocialIcon 
              href="https://www.facebook.com/NeonPixelDesigns" 
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ y: -3 }}
            >
              <FaFacebook />
            </SocialIcon>
            <SocialIcon 
              href="https://www.linkedin.com/company/neonpixel-designs/" 
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ y: -3 }}
            >
              <FaLinkedin />
            </SocialIcon>
          </SocialLinks>
        </FooterSection>

        <FooterSection>
          <h3>Our Locations</h3>
          {locations.map((location, index) => (
            <LocationItem key={index}>
              <LocationText>{location.city}</LocationText>
            </LocationItem>
          ))}
        </FooterSection>

        <FooterSection>
          <h3>Quick Links</h3>
          <FooterLinks>
            <li><Link to="/portfolio">Portfolio</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms">Terms of Service</Link></li>
          </FooterLinks>
        </FooterSection>

        <FooterSection>
          <h3>Services</h3>
          <FooterLinks>
            <li><Link to="/services/web-design">Web Design</Link></li>
          </FooterLinks>
        </FooterSection>
      </FooterContent>

      <Copyright>
        &copy; {currentYear} NeonPixel Designs. All rights reserved.
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
