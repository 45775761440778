import { createGlobalStyle } from 'styled-components';
import { Theme } from './theme';

const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 14px;
    }
  }

  body {
    font-family: ${props => props.theme.typography.fontFamily.body};
    line-height: 1.6;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    &:not([data-page="landing"]) {
      background-color: ${props => props.theme.colors.background};
      color: ${props => props.theme.colors.text};
      padding-top: 60px;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      line-height: 1.5;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.theme.typography.fontFamily.heading};
    font-weight: ${props => props.theme.typography.fontWeight.bold};
    line-height: 1.2;
    margin-bottom: ${props => props.theme.spacing.md};

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      margin-bottom: ${props => props.theme.spacing.sm};
    }
  }

  h1 {
    font-size: ${props => props.theme.typography.fontSize['5xl']};
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      font-size: ${props => props.theme.typography.fontSize['4xl']};
    }
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: ${props => props.theme.typography.fontSize['3xl']};
    }
  }

  h2 {
    font-size: ${props => props.theme.typography.fontSize['4xl']};
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      font-size: ${props => props.theme.typography.fontSize['3xl']};
    }
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: ${props => props.theme.typography.fontSize['2xl']};
    }
  }

  h3 {
    font-size: ${props => props.theme.typography.fontSize['3xl']};
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      font-size: ${props => props.theme.typography.fontSize['2xl']};
    }
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: ${props => props.theme.typography.fontSize.xl};
    }
  }

  p {
    margin-bottom: ${props => props.theme.spacing.md};
    font-size: ${props => props.theme.typography.fontSize.base};
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: ${props => props.theme.typography.fontSize.sm};
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  button {
    font-family: inherit;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    
    &:focus {
      outline: none;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
  }

  input, textarea, select, button {
    font-family: inherit;
    font-size: inherit;
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 16px; /* Prevents iOS zoom on focus */
    }
  }

  /* Improve touch targets on mobile */
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    button, a {
      min-height: 44px;
      min-width: 44px;
      padding: 0.5rem 1rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  /* Container styles */
  .container {
    width: 100%;
    max-width: ${props => props.theme.breakpoints.xl};
    margin: 0 auto;
    padding: 0 ${props => props.theme.spacing.md};
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      padding: 0 ${props => props.theme.spacing.sm};
    }
  }

  /* Grid system */
  .grid {
    display: grid;
    gap: ${props => props.theme.spacing.md};
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      gap: ${props => props.theme.spacing.sm};
    }
  }

  /* Form elements */
  input, textarea, select {
    padding: ${props => props.theme.spacing.sm} ${props => props.theme.spacing.md};
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.05);
    color: ${props => props.theme.colors.text};
    transition: all 0.3s ease;
    
    &:focus {
      outline: none;
      border-color: ${props => props.theme.colors.primary};
    }
  }

  /* Scrollbar styles */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.background};
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.primary}40;
    border-radius: 4px;
    
    &:hover {
      background: ${props => props.theme.colors.primary}80;
    }
  }

  /* Touch device optimizations */
  @media (hover: none) {
    a:hover, button:hover {
      opacity: 1;
    }
  }

  /* Prevent text size adjustment on orientation change */
  html {
    -webkit-text-size-adjust: 100%;
  }

  /* Main content area */
  main {
    min-height: 100vh;
    margin: 0;
    padding: 0;
  }

  #root {
    margin: 0;
    padding: 0;
  }
`;

export { GlobalStyles };
export default GlobalStyles;
