import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './styles/GlobalStyles';
import { theme } from './styles/theme';
import { Navbar } from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';
import PortfolioPage from './pages/PortfolioPage';
import GlowUpChallenge from './pages/GlowUpChallenge';
import ServicesPage from './pages/ServicesPage';
import BlogPage from './pages/BlogPage';
import BlogPostPage from './pages/BlogPostPage';
import WebDesign from './pages/WebDesign';
import GlassmorphismEffects from './pages/blog/glassmorphism-css-effects';
import ModernWebDesignTrends from './pages/blog/modern-web-design-trends-2024';
import WebDevelopmentBestPractices from './pages/blog/web-development-best-practices';
import WhyMostWebsitesFail from './pages/blog/why-most-websites-fail';
import FreeConsultation from './pages/FreeConsultation';
import DesignConsultation from './pages/DesignConsultation';
import DesignConsultation12 from './pages/DesignConsultation12';
import DesignConsultation13 from './pages/DesignConsultation13';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import SuccessPage from './pages/SuccessPage';
import IsraeliPortfolioPage from './pages/IsraeliPortfolioPage';
import OfferPage999 from './pages/OfferPage999';
import { PageTransition } from './components/Layout/PageTransition';

const App = () => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/design-consultation-12' || location.pathname === '/design-consultation-13';

  useEffect(() => {
    // Track page views in GTM
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'page_view',
        page_path: location.pathname,
        page_title: document.title,
        page_location: window.location.href
      });
    }
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles theme={theme} />
      <>
        {!isLandingPage && <Navbar />}
        <main>
          <PageTransition>
            <Routes location={location}>
              <Route path="/" element={<HomePage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/portfolio" element={<PortfolioPage />} />
              <Route path="/portfolio/israel" element={<IsraeliPortfolioPage />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/blog/glassmorphism-css-effects" element={<GlassmorphismEffects />} />
              <Route path="/blog/modern-web-design-trends-2024" element={<ModernWebDesignTrends />} />
              <Route path="/blog/web-development-best-practices" element={<WebDevelopmentBestPractices />} />
              <Route path="/blog/why-most-websites-fail" element={<WhyMostWebsitesFail />} />
              <Route path="/blog/:id" element={<BlogPostPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/web-design" element={<WebDesign />} />
              <Route path="/services/web-design" element={<WebDesign />} />
              <Route path="/free-consultation" element={<FreeConsultation />} />
              <Route path="/design-consultation" element={<DesignConsultation />} />
              <Route path="/design-consultation-12" element={<DesignConsultation12 />} />
              <Route path="/design-consultation-13" element={<DesignConsultation13 />} />
              <Route path="/glow-up-challenge" element={<GlowUpChallenge />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/terms" element={<TermsOfServicePage />} />
              <Route path="/999-offer" element={<OfferPage999 />} />
            </Routes>
          </PageTransition>
        </main>
        {!isLandingPage && <Footer />}
      </>
    </ThemeProvider>
  );
};

export default App;
