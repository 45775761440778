import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import SEO from '../../components/SEO/SEO';
import { Link } from 'react-router-dom';

const BlogContainer = styled.article`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: ${props => props.theme.colors.text};
`;

const BackLink = styled(Link)`
  display: inline-block;
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  margin-bottom: 2rem;
  &:hover {
    text-decoration: underline;
  }
`;

const Category = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 0.9rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.text};
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.textSecondary};
`;

const MetaInfo = styled.div`
  display: flex;
  gap: 1rem;
  color: ${props => props.theme.colors.textSecondary};
  margin-bottom: 2rem;
  font-size: 0.9rem;
`;

const Content = styled.div`
  line-height: 1.8;
  
  h2 {
    font-size: 1.8rem;
    color: ${props => props.theme.colors.primary};
    margin: 2.5rem 0 1rem;
  }

  h3 {
    font-size: 1.4rem;
    color: ${props => props.theme.colors.primary};
    margin: 2rem 0 1rem;
  }
  
  p {
    margin-bottom: 1.5rem;
  }
  
  ul {
    list-style-type: none;
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
    
    li {
      position: relative;
      margin-bottom: 0.75rem;
      
      &:before {
        content: "•";
        color: ${props => props.theme.colors.primary};
        position: absolute;
        left: -1.5rem;
      }
    }
  }
`;

const CTAButton = styled(Link)`
  display: inline-block;
  background: ${props => props.theme.colors.primary};
  color: #000000;
  padding: 1rem 2rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  margin-top: 2rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${props => props.theme.colors.secondary};
    transform: translateY(-2px);
  }
`;

const CTAContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;
`;

const WhyMostWebsitesFail = () => {
  return (
    <>
      <SEO 
        title="Why Most Websites Fail (And How to Fix Yours) | NeonPixel"
        description="Learn why most business websites fail to engage visitors and drive revenue, and discover actionable solutions to ensure your website succeeds."
        keywords="website optimization, conversion rate, user experience, web design, SEO, mobile optimization"
        url="/blog/why-most-websites-fail"
        type="article"
      />
      
      <BlogContainer>
        <BackLink to="/blog">← Back to Blog</BackLink>
        
        <Category>WEB DESIGN</Category>
        <Title>Why Most Websites Fail (And How to Fix Yours)</Title>
        <Subtitle>Turn Your Website into a Digital Sales Engine</Subtitle>
        
        <MetaInfo>
          <span>March 18, 2025</span>
          <span>•</span>
          <span>7 min read</span>
          <span>•</span>
          <span>by NeonPixel Designs</span>
        </MetaInfo>

        <Content>
          <p>
            A website is your digital storefront, yet most business websites fail to do what they were built for: engage visitors, generate leads, and drive revenue.
          </p>

          <p>
            In a world where attention spans are shrinking, your website has less than 10 seconds to convince a visitor to stay. If it doesn't, they leave—often for a competitor.
          </p>

          <p>
            So, why do so many websites fail? And more importantly, how can you ensure yours doesn't?
          </p>

          <h2>1. They Prioritize Looks Over Functionality</h2>
          <p>
            We've all seen it—websites that look sleek and modern but are a nightmare to navigate. A beautiful website that doesn't drive action is like a Ferrari without an engine—nice to look at, useless to drive.
          </p>

          <h3>How to Fix It:</h3>
          <ul>
            <li>Clarity first, creativity second – Visitors should instantly understand who you are, what you do, and why they should care.</li>
            <li>Minimalism wins – Reduce clutter and prioritize clear messaging over excessive graphics.</li>
            <li>Strong CTAs – Every page should guide visitors toward an action: book a call, download a resource, or make a purchase.</li>
          </ul>

          <h2>2. Slow Load Times Kill Conversions</h2>
          <p>
            A 1-second delay in page load time can lead to a 7% drop in conversions. Google ranks slow websites lower in search results, and frustrated users simply leave.
          </p>

          <h3>How to Fix It:</h3>
          <ul>
            <li>Optimize images and videos – Large files slow websites down. Compress and use modern formats like WebP.</li>
            <li>Reduce unnecessary scripts – Remove bloatware, unused plugins, and excessive tracking codes.</li>
            <li>Use a CDN (Content Delivery Network) – Faster global load times for users, no matter where they are.</li>
          </ul>

          <h2>3. Mobile Experience is an Afterthought</h2>
          <p>
            With over 60% of web traffic coming from mobile devices, a desktop-only design means losing potential customers. Yet, many websites still fail on mobile usability.
          </p>

          <h3>How to Fix It:</h3>
          <ul>
            <li>Design for mobile-first – Ensure fast loading, readable text, and easy navigation on small screens.</li>
            <li>Optimize for thumb-scrolling – Buttons and links should be easy to tap with one hand.</li>
            <li>Test across devices – What looks good on an iPhone may not on a Samsung. Test your site across multiple screen sizes.</li>
          </ul>

          <h2>4. SEO is Ignored (Until It's Too Late)</h2>
          <p>
            Web design and SEO go hand-in-hand. A great-looking website that doesn't rank on Google is like a billboard in the desert—no one sees it.
          </p>

          <h3>How to Fix It:</h3>
          <ul>
            <li>SEO-friendly structure – Clean URLs, meta tags, and proper headings (H1, H2, H3) improve rankings.</li>
            <li>Speed = Better Rankings – Fast-loading websites get priority in search results.</li>
            <li>High-quality content – Google rewards websites with valuable, well-written content that answers user questions.</li>
          </ul>

          <h2>5. No Clear Conversion Strategy</h2>
          <p>
            A website should not just be informational—it should be a lead generation machine. Many businesses build websites without a clear strategy to convert visitors into customers.
          </p>

          <h3>How to Fix It:</h3>
          <ul>
            <li>Create a sales funnel – Offer a free resource (lead magnet) in exchange for an email address.</li>
            <li>Use social proof – Testimonials, case studies, and reviews increase trust and improve conversions.</li>
            <li>A/B test everything – Headlines, CTAs, and layouts should be tested to see what drives the most conversions.</li>
          </ul>

          <h2>Your Website Should Work for Your Business</h2>
          <p>
            A website is an investment, not an expense. When built correctly, it attracts customers, generates leads, and drives revenue—24/7.
          </p>

          <p>
            At Neon Pixel Designs, we specialize in websites that don't just look good but perform like digital sales engines.
          </p>

          <p>
            Are you making any of these mistakes? Let's talk about how to fix them.
          </p>

          <CTAContainer>
            <CTAButton to="/contact">Let's Fix Your Website Today</CTAButton>
          </CTAContainer>
        </Content>
      </BlogContainer>
    </>
  );
};

export default WhyMostWebsitesFail;
