import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

// Bind modal to your appElement
Modal.setAppElement('#root');

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    zIndex: 9999,
    backdropFilter: 'blur(5px)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90%',
    maxHeight: '90%',
    padding: '0',
    border: 'none',
    background: 'none',
    overflow: 'visible'
  }
};

const CloseButton = styled.button`
  position: absolute;
  top: -40px;
  right: -40px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:hover {
    color: ${props => props.theme.colors.primary};
    background: rgba(0, 0, 0, 0.8);
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    top: -50px;
    right: 0;
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 85vh;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
`;

interface ScreenshotModalProps {
  isOpen: boolean;
  imageUrl: string;
  onClose: () => void;
}

const ScreenshotModal: React.FC<ScreenshotModalProps> = ({ isOpen, imageUrl, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Screenshot Modal"
    >
      <CloseButton onClick={onClose} aria-label="Close modal">
        <FaTimes />
      </CloseButton>
      <Image src={imageUrl} alt="Project screenshot" />
    </Modal>
  );
};

export default ScreenshotModal;
