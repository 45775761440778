import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO/SEO';
import { Services } from '../components/Services/Services';

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${props => props.theme.colors.background};
`;

const MainContent = styled.main`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledServices = styled(Services)`
  width: 100%;
  margin: 2rem 0;
`;

const ServicesPage = () => {
  const pageTitle = "Professional Web Design Services";
  const pageDescription = "Transform your online presence with our expert web design services. We create stunning, user-friendly websites that drive results.";
  const pageKeywords = "web design, responsive design, UI/UX design, website design, professional web design";
  
  const schema = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "https://neonpixeldesigns.com/services#webdesign",
    "name": pageTitle,
    "description": pageDescription,
    "provider": {
      "@type": "Organization",
      "name": "NeonPixel",
      "description": "Professional web design services in London, Melbourne, and Israel",
      "url": "https://neonpixeldesigns.com",
      "serviceType": ["Web Design", "E-commerce Solutions", "Digital Marketing"],
      "areaServed": "United Kingdom"
    }
  };

  return (
    <PageContainer>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://neonpixeldesigns.com/services" />
        <link rel="canonical" href="https://neonpixeldesigns.com/services" />
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      </Helmet>
      <SEO
        title={pageTitle}
        description={pageDescription}
        keywords={pageKeywords}
        url="/services"
        type="services"
        schema={schema}
      />
      <PageHeader
        title="Our Services"
        subtitle="Crafting Digital Excellence"
      />
      <MainContent>
        <StyledServices showFeatures={true} />
      </MainContent>
    </PageContainer>
  );
};

export default ServicesPage;
