import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO/SEO';
import { FaCheckCircle } from 'react-icons/fa';
import { useSupressResizeObserverError } from '../hooks/useSupressResizeObserverError';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 4rem 2rem;
  text-align: center;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled(motion.div)`
  font-size: 4rem;
  color: #00ffff;
  margin-bottom: 2rem;
  
  svg {
    filter: drop-shadow(0 0 10px rgba(0, 255, 255, 0.5));
  }
`;

const Title = styled(motion.h1)`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #00ffff 0%, #8A2BE2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

const Message = styled(motion.p)`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 1.6;
  color: ${props => props.theme.colors.text};
  max-width: 600px;
  opacity: 0.9;
`;

const ButtonsContainer = styled(motion.div)`
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
  justify-content: center;

  @media (max-width: 480px) {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }
`;

interface StyledButtonProps {
  $variant?: 'primary' | 'secondary';
}

const Button = styled(motion(Link))<StyledButtonProps>`
  padding: 0.4rem 1.25rem;
  background: ${props => props.$variant === 'secondary' ? 'transparent' : props.theme.colors.primary};
  color: ${props => props.$variant === 'secondary' ? props.theme.colors.primary : props.theme.colors.background};
  border-radius: 4px;
  font-weight: 600;
  transition: ${props => props.theme.transitions?.default || 'all 0.3s ease'};
  box-shadow: ${props => props.$variant === 'secondary' ? 
    'none' : 
    `0 0 20px ${props.theme.colors.primary}40`};
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 34px;
  font-size: 0.95rem;
  text-decoration: none;
  justify-content: center;
  border: ${props => props.$variant === 'secondary' ? `1px solid ${props.theme.colors.primary}` : 'none'};

  &:hover {
    background: ${props => props.$variant === 'secondary' ? 
      'transparent' : 
      props.theme.colors.secondary};
    color: ${props => props.$variant === 'secondary' ? 
      props.theme.colors.secondary : 
      props.theme.colors.background};
    box-shadow: ${props => props.$variant === 'secondary' ? 
      'none' : 
      `0 0 30px ${props.theme.colors.secondary}40`};
    border-color: ${props => props.$variant === 'secondary' ? props.theme.colors.secondary : 'transparent'};
  }

  @media (max-width: 480px) {
    width: 100%;
    text-align: center;
  }
`;

const containerVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.6,
      staggerChildren: 0.2
    }
  }
};

const childVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 }
};

const SuccessPage: React.FC = () => {
  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  useSupressResizeObserverError();

  return (
    <>
      <SEO
        title="Message Sent Successfully | Neon Pixel"
        description="Thank you for contacting Neon Pixel. We have received your message and will get back to you soon."
        keywords="contact confirmation, message sent, thank you"
        url="/success"
        type="website"
        noindex={true}
      />
      <Container>
        <motion.div
          variants={containerVariants}
          initial="initial"
          animate="animate"
        >
          <IconWrapper variants={childVariants}>
            <FaCheckCircle />
          </IconWrapper>
          
          <Title variants={childVariants}>
            Message Sent Successfully!
          </Title>
          
          <Message variants={childVariants}>
            Thank you for reaching out! We've received your message and will get back to you 
            within 24-48 hours. In the meantime, feel free to explore our portfolio or check 
            out our services.
          </Message>
          
          <ButtonsContainer variants={childVariants}>
            <Button 
              to="/"
              $variant="primary"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Back to Home
            </Button>
            <Button 
              to="/portfolio"
              $variant="secondary"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              View Our Work
            </Button>
          </ButtonsContainer>
        </motion.div>
      </Container>
    </>
  );
};

export default SuccessPage;
