import React from 'react';
import styled from 'styled-components';
import { PageContainer as Container } from '../components/Layout/PageContainer';
import SEO from '../components/SEO/SEO';
import PageHeader from '../components/PageHeader/PageHeader';

const ContentSection = styled.section`
  padding: 2rem 0;
  color: ${props => props.theme.colors.text};
  max-width: 800px;
  margin: 0 auto;
`;

const Section = styled.div`
  margin-bottom: 2.5rem;
  background: ${props => props.theme.colors.cardBg};
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const SectionTitle = styled.h2`
  color: ${props => props.theme.colors.primary};
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  
  li {
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    
    &:before {
      content: "✅";
      flex-shrink: 0;
    }
    
    &.warning:before {
      content: "⚠";
    }
  }
`;

const PrizeBox = styled.div`
  background: linear-gradient(135deg, #2c3e50 0%, #3498db 100%);
  padding: 2rem;
  border-radius: 12px;
  margin: 2rem 0;
  color: white;
  
  h3 {
    color: #ffd700;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  background: ${props => props.theme.colors.primary};
  color: #000000;
  padding: 1rem 2rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;
  transition: transform 0.2s;
  
  &:hover {
    transform: translateY(-2px);
  }
`;

const GlowUpChallenge: React.FC = () => {
  return (
    <>
      <SEO
        title="Website Glow-Up Challenge - Win a Free Website Redesign"
        description="Enter our Website Glow-Up Challenge for a chance to win a free custom website redesign worth thousands of shekels. Runner-up special offer available!"
        keywords="website contest, website redesign, business website, web design competition, website glow-up"
        url="/glow-up-challenge"
        type="article"
      />
      
      <PageHeader
        title="Website Glow-Up Challenge"
        subtitle="Win a Complete Website Transformation"
      />

      <Container>
        <ContentSection>
          <Section>
            <SectionTitle>1. Eligibility</SectionTitle>
            <List>
              <li>The contest is open to business owners only (freelancers and individuals without a registered business are not eligible)</li>
              <li>The participant must have an existing website that they are willing to have redesigned</li>
              <li className="warning">E-commerce websites are NOT eligible (we only redesign non-eCommerce business websites)</li>
              <li>The business must operate within a serviceable industry</li>
              <li>Participants must be 18 years or older to enter</li>
              <li>The contest is not affiliated with Meta (Facebook/Instagram) in any way</li>
            </List>
          </Section>

          <Section>
            <SectionTitle>2. How to Enter</SectionTitle>
            <List>
              <li>Comment on the contest post with their current website URL</li>
              <li>Explain why they need a website redesign in the comments</li>
              <li>Follow our Facebook page to stay updated on the contest and winner announcement</li>
              <li>Optional: Tag another business owner who might need a website glow-up</li>
              <li className="warning">No purchase is necessary to enter</li>
            </List>
          </Section>

          <Section>
            <SectionTitle>3. Winner Selection & Notification</SectionTitle>
            <List>
              <li>The winner will be chosen based on need, creativity, and impact (not randomly)</li>
              <li>Selection criteria includes current website condition, potential impact, and entry clarity</li>
              <li>Winners will be notified via Facebook DM and comment reply</li>
              <li className="warning">Winners must respond within 48 hours or another participant will be selected</li>
            </List>
          </Section>

          <Section>
            <SectionTitle>4. Prize Details</SectionTitle>
            <PrizeBox>
              <h3>🎁 Grand Prize Winner Receives:</h3>
              <List>
                <li>A fully custom-coded website redesign (No templates!)</li>
                <li>Modern, high-converting, and SEO-optimized site</li>
                <li>Mobile & desktop optimization</li>
                <li>Website completion within 4 weeks after project kick-off</li>
              </List>
            </PrizeBox>
            <List>
              <li className="warning">Prize does NOT include e-commerce functionality</li>
              <li className="warning">Domain registration and hosting costs not included</li>
              <li className="warning">Additional revisions outside project scope not included</li>
            </List>
          </Section>

          <Section>
            <SectionTitle>5. Runner-Up Special Offer</SectionTitle>
            <PrizeBox>
              <h3>🚀 Get a New Custom Website for Only 999 Shekels!</h3>
              <List>
                <li>Fully custom-coded website (no templates)</li>
                <li>Mobile-friendly, fast-loading, and SEO-optimized</li>
                <li>Custom branding & layout</li>
                <li>Valid for 30 days after winner announcement</li>
              </List>
            </PrizeBox>
            <List>
              <li className="warning">E-commerce functionality not included</li>
              <li className="warning">Domain and hosting fees not included</li>
              <li className="warning">Additional features outside core design scope not included</li>
            </List>
          </Section>

          <Section>
            <SectionTitle>6. General Conditions</SectionTitle>
            <List>
              <li>We reserve the right to disqualify inappropriate or ineligible entries</li>
              <li>Participants agree to allow showcase of website transformation</li>
              <li>Contest may be modified or canceled if unforeseen circumstances arise</li>
            </List>
          </Section>

          <Section>
            <SectionTitle>7. Privacy Policy</SectionTitle>
            <List>
              <li>Participant details will not be shared with third parties</li>
              <li>Contact details used only for contest-related communication</li>
            </List>
          </Section>

          <Section>
            <SectionTitle>8. Legal Disclaimer</SectionTitle>
            <List>
              <li>Contest not sponsored, endorsed, or administered by Meta</li>
              <li>Participants release Facebook and Instagram from contest responsibility</li>
            </List>
          </Section>

          <Section>
            <SectionTitle>📌 Have Questions?</SectionTitle>
            <p>If you have any questions about the contest, send us a message on Facebook!</p>
            <CTAButton href="https://www.facebook.com/NeonPixelDesigns" target="_blank" rel="noopener noreferrer">
              Contact Us on Facebook
            </CTAButton>
          </Section>
        </ContentSection>
      </Container>
    </>
  );
};

export default GlowUpChallenge;
