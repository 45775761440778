import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useNavigate, Link } from 'react-router-dom';
import services from '../../data/services';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FaCode, FaPalette, FaMobileAlt, FaRocket, FaCog, FaChartLine } from 'react-icons/fa';

const ServicesSection = styled.section`
  padding: 4rem 0;
  width: 100%;
  background: ${props => props.theme.colors.background};
  display: flex;
  justify-content: center;
`;

const ServicesContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
  justify-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const ServiceCard = styled(motion.div)`
  background: ${props => props.theme.colors.backgroundLight};
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 12px;
  padding: 3.5rem;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0,0,0,0.12);
  }
`;

const LearnMoreButton = styled(Link)`
  display: inline-block;
  margin-top: 2rem;
  padding: 0.75rem 2rem;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  border-radius: 30px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  border: 2px solid ${props => props.theme.colors.primary};

  &:hover {
    background: transparent;
    color: ${props => props.theme.colors.primary};
    transform: translateY(-2px);
  }
`;

const ServiceIcon = styled.div`
  width: 60px;
  height: 60px;
  background: ${props => props.theme.colors.background};
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.primary};
  font-size: 1.8rem;
  position: relative;
  z-index: 1;
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.text};
`;

const ServiceDescription = styled.p`
  color: ${props => props.theme.colors.textLight};
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  max-width: 700px;
`;

const FeaturesList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem 3rem;
  margin-top: 2.5rem;
  width: 100%;
  max-width: 800px;
  text-align: left;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: ${props => props.theme.colors.text};
  font-size: 1.1rem;
  line-height: 1.5;
  padding: 0.75rem;
  background: ${props => props.theme.colors.background};
  border-radius: 8px;
  transition: all 0.3s ease;

  &:hover {
    background: ${props => props.theme.colors.backgroundLight};
    transform: translateX(5px);
  }

  &:before {
    content: "•";
    color: ${props => props.theme.colors.primary};
    font-size: 1.75rem;
  }
`;

const GlowEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at 50% 0%,
    ${props => props.theme.colors.primary}10 0%,
    transparent 70%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  
  ${ServiceCard}:hover & {
    opacity: 1;
  }
`;

interface Service {
  title: string;
  description: string;
  icon: React.ComponentType;
  path: string;
  features?: string[];
}

interface ServicesProps {
  className?: string;
  showFeatures?: boolean;
}

const Services: React.FC<ServicesProps> = ({ className, showFeatures = false }) => {
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const [, setIsAnimating] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
      setIsAnimating(false);
    };
  }, []);

  const handleServiceClick = (path: string) => {
    navigate(path);
  };

  return (
    <ServicesSection className={className}>
      <ServicesContainer>
        {isMounted && (
          <ServicesGrid>
            {services.map((service: Service, index: number) => {
              const Icon = service.icon;
              return (
                <ServiceCard
                  key={index}
                  onClick={() => handleServiceClick(service.path)}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <ServiceIcon>
                    <Icon />
                  </ServiceIcon>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDescription>{service.description}</ServiceDescription>
                  {showFeatures && service.features && (
                    <FeaturesList>
                      {service.features.map((feature: string, i: number) => (
                        <Feature key={i}>{feature}</Feature>
                      ))}
                    </FeaturesList>
                  )}
                  <LearnMoreButton to={service.path}>Learn More</LearnMoreButton>
                </ServiceCard>
              );
            })}
          </ServicesGrid>
        )}
      </ServicesContainer>
    </ServicesSection>
  );
};

export { Services };
