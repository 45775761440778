import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaDesktop, FaMobile } from 'react-icons/fa';
import { theme } from '../../styles/theme';
import ScreenshotModal from '../Modal/ScreenshotModal';
import { portfolioImages } from '../../assets/portfolio/images';

const ProjectGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  padding-bottom: 4rem;

  @media (max-width: 768px) {
    gap: 1.5rem;
  }
`;

const ProjectCard = styled(motion.div)`
  background: ${theme.colors.backgroundLight};
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`;

const ProjectImage = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  ${ProjectCard}:hover & img {
    transform: scale(1.05);
  }
`;

const ProjectContent = styled.div`
  padding: 1.5rem;
`;

const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${theme.colors.text};
`;

const ProjectDescription = styled.p`
  color: ${theme.colors.textSecondary};
  margin-bottom: 1.5rem;
  line-height: 1.6;
`;

const TechStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const TechTag = styled.span`
  background: rgba(255, 255, 255, 0.1);
  padding: 0.25rem 0.75rem;
  border-radius: 50px;
  font-size: 0.85rem;
  color: ${theme.colors.textSecondary};
`;

const ScreenshotButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
`;

const ScreenshotButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: ${theme.colors.text};
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

interface Project {
  id: number;
  title: string;
  description: string;
  image: string;
  category: string;
  features: string[];
  techStack: string[];
  link?: string;
  desktopScreenshot?: string;
  mobileScreenshot?: string;
}

interface ProjectProps {
  project: Project;
}

const Project: React.FC<ProjectProps> = ({ project }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const handleScreenshotClick = (imageUrl: string) => {
    setCurrentImage(imageUrl);
    setShowModal(true);
  };

  return (
    <>
      <ProjectCard
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
      >
        <ProjectImage>
          <img src={project.image} alt={project.title} />
        </ProjectImage>
        <ProjectContent>
          <ProjectTitle>{project.title}</ProjectTitle>
          <ProjectDescription>{project.description}</ProjectDescription>
          <TechStack>
            {project.techStack.slice(0, 3).map((tech, index) => (
              <TechTag key={index}>{tech}</TechTag>
            ))}
          </TechStack>
          <ScreenshotButtons>
            {project.desktopScreenshot && (
              <ScreenshotButton
                onClick={() => handleScreenshotClick(project.desktopScreenshot!)}
              >
                <FaDesktop /> Desktop
              </ScreenshotButton>
            )}
            {project.mobileScreenshot && (
              <ScreenshotButton
                onClick={() => handleScreenshotClick(project.mobileScreenshot!)}
              >
                <FaMobile /> Mobile
              </ScreenshotButton>
            )}
          </ScreenshotButtons>
        </ProjectContent>
      </ProjectCard>
      <ScreenshotModal
        isOpen={showModal}
        imageUrl={currentImage}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

// Israeli projects data
const israeliProjects = [
  {
    id: 1,
    title: "Grossman Tennis",
    description: "A dynamic marketing automation business website showcasing AI-powered solutions that help companies streamline their marketing efforts and boost engagement.",
    image: portfolioImages.grossmanTennis,
    category: "Web Development",
    features: [
      "Multilingual Support",
      "SEO Optimization",
      "Image Optimization",
      "Performance Optimized",
      "Responsive Design",
      "Modern UI/UX"
    ],
    techStack: [
      "Astro",
      "TailwindCSS",
      "Autoprefixer"
    ],
    desktopScreenshot: "/images/images-biz/grossman-desktop.png",
    mobileScreenshot: "/images/images-biz/grossman-mobile.png"
  }
];

interface IsraeliPortfolioProps {
  show: boolean;
}

const IsraeliPortfolio: React.FC<IsraeliPortfolioProps> = ({ show }) => {
  if (!show) return null;

  return (
    <div>
      <h2>Israeli Projects</h2>
      <ProjectGrid>
        <AnimatePresence>
          {israeliProjects.map((project) => (
            <Project key={project.id} project={project} />
          ))}
        </AnimatePresence>
      </ProjectGrid>
    </div>
  );
};

export default IsraeliPortfolio;
