import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

interface ImageWithFallbackProps {
  src: string;
  alt: string;
  className?: string;
  width?: string;
  height?: string;
  fallbackContent?: React.ReactNode;
  loading?: 'lazy' | 'eager';
}

const ImageContainer = styled(motion.div)<{ $height?: string }>`
  position: relative;
  height: ${props => props.$height || '100%'};
  width: 100%;
  overflow: hidden;
  background: ${props => props.theme.colors.backgroundLight};
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
`;

const FallbackContainer = styled.div<{ $height?: string }>`
  width: 100%;
  height: ${props => props.$height || '100%'};
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, 
    ${props => props.theme.colors.primary}22,
    ${props => props.theme.colors.secondary}22
  );
  color: ${props => props.theme.colors.primary};
  font-family: 'Outfit', sans-serif;
  text-align: center;
  padding: 1rem;
`;

const LoadingOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    ${props => props.theme.colors.background},
    ${props => props.theme.colors.primary}11,
    ${props => props.theme.colors.background}
  );
  background-size: 200% 100%;
  animation: loading 1.5s infinite;

  @keyframes loading {
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
  }
`;

const RetryButton = styled.button`
  background: ${props => props.theme.colors.primary}33;
  border: 1px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 0.5rem;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.theme.colors.primary}66;
  }
`;

export const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  src,
  alt,
  className,
  width,
  height,
  fallbackContent,
  loading = 'lazy'
}) => {
  const [imageError, setImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setImageError(false);
    setIsLoading(true);
  }, [src]);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setImageError(true);
    setIsLoading(false);
  };

  const handleRetry = () => {
    setIsLoading(true);
    setImageError(false);
    // Add cache-busting parameter
    setCurrentSrc(`${src}?retry=${Date.now()}`);
  };

  const [currentSrc, setCurrentSrc] = useState(src);

  return (
    <ImageContainer className={className} $height={height}>
      {!imageError ? (
        <StyledImage
          src={currentSrc}
          alt={alt}
          onError={handleError}
          onLoad={handleLoad}
          loading={loading}
          aria-hidden={false}
          role="img"
        />
      ) : (
        <FallbackContainer $height={height} role="img" aria-label={alt}>
          {fallbackContent || (
            <div>
              <div>{alt}</div>
              <RetryButton onClick={handleRetry}>
                Retry Loading Image
              </RetryButton>
            </div>
          )}
        </FallbackContainer>
      )}
      {isLoading && (
        <LoadingOverlay />
      )}
    </ImageContainer>
  );
};

export default ImageWithFallback;
