import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { motion, Variants } from 'framer-motion';
import SEO from '../components/SEO/SEO';
import { Link } from 'react-router-dom';
import { portfolioImages } from '../assets/portfolio/images';
import { FaCode, FaPalette, FaMobileAlt, FaRocket, FaCogs, FaLightbulb, FaUsers, FaLaptopCode } from 'react-icons/fa';
import { SiTypescript, SiNextdotjs, SiTailwindcss } from 'react-icons/si';
import { FaReact, FaNodeJs, FaFigma } from 'react-icons/fa';
import { Services } from '../components/Services/Services';
import { PageContainer } from '../components/Layout/PageContainer';
import { FaFacebook, FaLinkedin } from 'react-icons/fa';

const HeroSection = styled.section`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
  overflow: hidden;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 20, 0.95) 0%,
    rgba(0, 20, 20, 0.95) 50%,
    rgba(20, 0, 20, 0.95) 100%
  );
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;

  @media (max-width: 768px) {
    padding-top: 6rem;
    justify-content: flex-start;
    min-height: 75vh;
  }

  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
      circle at center,
      rgba(0, 255, 255, 0.1) 0%,
      rgba(65, 105, 225, 0.1) 30%,
      rgba(138, 43, 226, 0.1) 60%,
      rgba(255, 105, 180, 0.1) 100%
    );
    pointer-events: none;
    z-index: 1;
  }
`;

const HeroTitle = styled(motion.h1)`
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
  background: linear-gradient(
    to right,
    #00ffff 0%,    /* Cyan */
    #4169e1 30%,   /* Royal Blue */
    #8a2be2 60%,   /* Blue Violet */
    #ff69b4 100%   /* Hot Pink */
  );
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  animation: gradientMove 8s ease infinite;

  @keyframes gradientMove {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const HeroSubtitle = styled(motion.h2)`
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  font-weight: 400;
  margin-bottom: 2rem;
  text-align: center;
  color: ${props => props.theme.colors.text};
  opacity: 0.9;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
  padding: 0 1rem;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 0 1.5rem;
    white-space: normal;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0 2rem;
  }
`;

const Button = styled(Link)`
  display: inline-block;
  padding: 1rem 2rem;
  min-width: 200px;
  border: 2px solid ${props => props.theme.colors.primary};
  border-radius: 30px;
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  background: transparent;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 280px;
  }

  &:hover {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.background};
  }
`;

const IconsContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const FloatingIcon = styled(motion.div)`
  position: absolute;
  color: ${props => props.theme.colors.primary};
  opacity: 0.3;
  font-size: 3.5rem;
  filter: drop-shadow(0 0 10px ${props => props.theme.colors.primary});

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2.5rem;
  }
`;

const Section = styled.section`
  padding: 6rem 2rem;
  text-align: center;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 4rem 1rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  color: ${props => props.theme.colors.text};
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 3rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

const SectionSubtitle = styled.p`
  color: ${props => props.theme.colors.text};
  font-size: 1.2rem;
  margin-bottom: 3rem;
  max-width: 800px;
  text-align: center;
  line-height: 1.6;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    padding: 0 1rem;
  }
`;

const ProjectsSection = styled(Section)`
  background: ${props => props.theme.colors.background};
`;

const PortfolioPreview = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
    padding: 0;
  }
`;

const ProjectCard = styled(motion.article)`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  height: 300px;
  width: 100%;
  margin: 0 auto;
  transform-origin: center;
  will-change: transform;
`;

const ProjectImage = styled.div<{ bgImage: string }>`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;

  ${ProjectCard}:hover & {
    transform: scale(1.05);
  }
`;

const ProjectOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  color: white;
  transform: translateY(0);
  transition: transform 0.3s ease;
`;

const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: white;
`;

const ProjectCategory = styled.span`
  font-size: 1rem;
  color: ${props => props.theme.colors.primary};
`;

const ProcessSection = styled.section`
  padding: 6rem 2rem;
  background: ${props => props.theme.colors.background};
  text-align: center;

  h2 {
    ${SectionTitle}
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 4rem 1rem;
  }
`;

const ProcessGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
    padding: 1rem;
    gap: 1.5rem;
  }
`;

const ProcessCard = styled(motion.div)`
  background: ${props => props.theme.colors.backgroundLight};
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  transform-origin: center;
  will-change: transform;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 1.5rem;
  }
`;

const ProcessIcon = styled.div`
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
  
  svg {
    filter: drop-shadow(0 0 8px ${props => props.theme.colors.primary}40);
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 2rem;
  }
`;

const ProcessTitle = styled.h3`
  color: ${props => props.theme.colors.text};
  font-size: 1.5rem;
  margin-bottom: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 1.3rem;
  }
`;

const ProcessDescription = styled.p`
  color: ${props => props.theme.colors.textLight};
  font-size: 1rem;
  line-height: 1.6;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 0.95rem;
  }
`;

const TestimonialsSection = styled.section`
  padding: 6rem 2rem;
  background: ${props => props.theme.colors.background};
  text-align: center;
  background: linear-gradient(135deg,
    ${props => props.theme.colors.primary}10,
    ${props => props.theme.colors.secondary}10
  );

  h2 {
    ${SectionTitle}
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 4rem 1rem;
  }
`;

const TestimonialsGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
`;

const TestimonialCard = styled(motion.div)`
  background: ${props => props.theme.colors.backgroundLight};
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  transform-origin: center;
  will-change: transform;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 1.5rem;
  }
`;

const TestimonialText = styled.p`
  color: ${props => props.theme.colors.text};
  font-style: italic;
  margin-bottom: 1.5rem;
  line-height: 1.6;
  font-size: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 0.9375rem;
    margin-bottom: 1rem;
  }
`;

const TestimonialAuthor = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const AuthorImage = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const AuthorInfo = styled.div`
  h4 {
    color: ${props => props.theme.colors.primary};
    margin-bottom: 0.25rem;
  }
  p {
    color: ${props => props.theme.colors.textSecondary};
    font-size: 0.9rem;
  }
`;

const CTASection = styled.section`
  text-align: center;
  padding: 6rem 2rem;
  background: linear-gradient(135deg,
    ${props => props.theme.colors.primary}15,
    ${props => props.theme.colors.secondary}15
  );
`;

const ServicesSection = styled.section`
  padding: 6rem 0;
  background: ${props => props.theme.colors.background};
  width: 100%;
`;

const ServicesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StyledServices = styled(Services)`
  width: 100%;
  margin-top: 2rem;
`;

const iconPositions = [
  { icon: FaCode, x: "10%", y: "20%", delay: 0 },
  { icon: FaPalette, x: "85%", y: "15%", delay: 0.5 },
  { icon: FaMobileAlt, x: "15%", y: "75%", delay: 1 },
  { icon: FaRocket, x: "80%", y: "70%", delay: 1.5 },
  { icon: FaCogs, x: "50%", y: "85%", delay: 2 },
  { icon: FaLightbulb, x: "90%", y: "40%", delay: 2.5 }
];

const cardVariants: Variants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  }
};

const staggerContainerVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.1
    }
  }
};

const HomePage: React.FC = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    // Prevent ResizeObserver loop limit exceeded error
    const resizeObserverError = (error: ErrorEvent) => {
      if (error.message === 'ResizeObserver loop completed with undelivered notifications.') {
        error.stopImmediatePropagation();
      }
    };

    window.addEventListener('error', resizeObserverError);

    return () => {
      window.removeEventListener('error', resizeObserverError);
    };
  }, []);

  const generateRandomMovement = (duration: number) => ({
    x: [0, -20, 20, -10, 10, 0],
    y: [0, 20, -20, 10, -10, 0],
    transition: {
      duration,
      ease: "linear",
      repeat: Infinity,
      repeatType: "reverse" as const,
    },
  });

  const featuredProjects = [
    {
      id: 1,
      title: "Jada Trading",
      description: "A modern website for a premium toy distribution company, connecting businesses worldwide through elegant design and seamless user experience.",
      image: portfolioImages.jadaTrading,
      features: [
        "Modern UI Design",
        "Optimized Performance",
        "Responsive Layout",
        "Interactive Elements",
        "SEO Best Practices"
      ],
      link: "/portfolio/jada-trading"
    },
    {
      id: 2,
      title: "Natali.ai",
      description: "A dynamic marketing automation business website showcasing AI-powered solutions that help companies streamline their marketing efforts and boost engagement.",
      image: portfolioImages.nataliAi,
      features: [
        "Modern UI Design",
        "Smooth Animations",
        "Responsive Layout",
        "Fast Development",
        "Custom Design System"
      ],
      link: "/portfolio/natali-ai"
    },
    {
      id: 3,
      title: "S2 Management",
      description: "A modern website for an AI-powered CCTV monitoring service, providing remote security solutions through efficient technology.",
      image: portfolioImages.s2Management,
      features: [
        "Modern UI Design",
        "Responsive Layout",
        "Smooth Animations",
        "SEO Optimization",
        "Performance Focused"
      ],
      link: "/portfolio/s2-management"
    }
  ];

  const testimonials = [
    {
      id: 1,
      name: "Robert C.",
      role: "CEO at Jada Trading",
      content: "Working with Damien was a fantastic experience. He delivered a modern, user-friendly website that perfectly represents our toy distribution business. His attention to detail and understanding of our needs was exceptional.",
      image: "/images/testimonials/robert.jpg"
    },
    {
      id: 2,
      name: "Clifton F.",
      role: "Founder of Natali.ai",
      content: "Damien created an outstanding website for our marketing automation business. His expertise in modern web technologies and design principles helped us showcase our AI solutions effectively.",
      image: "/images/testimonials/clifton.jpg"
    },
    {
      id: 3,
      name: "Jordi R.",
      role: "Director at CommSearch",
      content: "The website Damien built for CommSearch exceeded our expectations. His technical skills and understanding of our communication consultancy needs resulted in a perfect digital presence for our business.",
      image: "/images/testimonials/jordi.jpg"
    }
  ];

  const process = [
    {
      icon: FaUsers,
      title: "Discovery",
      description: "We start by understanding your goals, target audience, and unique requirements."
    },
    {
      icon: FaPalette,
      title: "Design",
      description: "Our designers create stunning visuals that align with your brand identity."
    },
    {
      icon: FaLaptopCode,
      title: "Development",
      description: "We build your website using the latest technologies and best practices."
    },
    {
      icon: FaRocket,
      title: "Launch",
      description: "After thorough testing, we deploy your site and provide ongoing support."
    }
  ];

  return (
    <PageContainer noPadding>
      <Helmet>
        <title>NeonPixel Designs | Modern Web Design & Development Agency</title>
        <meta name="description" content="Transform your digital presence with NeonPixel Designs. We create stunning websites and web applications that elevate your brand and engage your audience. Expert web design & development services in London." />
        <meta name="keywords" content="web design agency, web development, UI/UX design, responsive websites, digital agency, London web design, responsive websites" />
        
        {/* Open Graph tags */}
        <meta property="og:title" content="NeonPixel Designs | Modern Web Design & Development Agency" />
        <meta property="og:description" content="Transform your digital presence with NeonPixel Designs. Expert web design & development services that elevate your brand." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://neonpixeldesigns.com" />
        <meta property="og:image" content="/images/neonpixel-og-image.jpg" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="NeonPixel Designs | Modern Web Design & Development Agency" />
        <meta name="twitter:description" content="Transform your digital presence with NeonPixel Designs. Expert web design & development services that elevate your brand." />
        <meta name="twitter:image" content="/images/neonpixel-og-image.jpg" />
        
        {/* Additional SEO meta tags */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="NeonPixel Designs" />
        <link rel="canonical" href="https://neonpixeldesigns.com" />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "NeonPixel Designs",
            "description": "Modern web design and development agency specialising in creating stunning digital experiences.",
            "image": "/images/neonpixel-logo.png",
            "url": "https://neonpixeldesigns.com",
            "telephone": "+44 (0)20 7123 4567",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "32 London Bridge Street",
              "addressLocality": "London",
              "postalCode": "SE1 9SG",
              "addressCountry": "GB"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "51.5045",
              "longitude": "-0.0865"
            },
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "09:00",
              "closes": "17:30"
            },
            "sameAs": [
              "https://www.linkedin.com/company/neonpixel-designs",
              "https://twitter.com/neonpixeldesign",
              "https://www.instagram.com/neonpixeldesigns"
            ]
          })}
        </script>
      </Helmet>
      <SEO
        title="Creative Web Design & Development Agency"
        description="NeonPixel Designs - A premier web design and development agency specializing in creating stunning, high-performance websites and digital experiences for modern businesses."
        keywords="web design agency, web development, UI/UX design, responsive websites, digital agency, custom web development, modern web design, React development"
        url="/"
        type="website"
      />
      <HeroSection role="banner">
        <IconsContainer aria-hidden="true">
          {iconPositions.map((item, index) => (
            <FloatingIcon
              key={index}
              style={{ left: item.x, top: item.y }}
              initial={{ opacity: 0, scale: 0 }}
              animate={{ 
                opacity: 0.3,
                scale: 1,
                y: [0, -30, 0]
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
                delay: item.delay,
                y: {
                  duration: 5,
                  repeat: Infinity,
                  repeatType: "reverse",
                  ease: "easeInOut"
                }
              }}
              whileHover={{
                scale: 1.2,
                opacity: 0.8,
                filter: "drop-shadow(0 0 20px #00ffff)"
              }}
            >
              <item.icon aria-hidden="true" />
            </FloatingIcon>
          ))}
        </IconsContainer>
        <HeroTitle>Transforming Ideas into Digital Reality</HeroTitle>
        <HeroSubtitle>Modern web design agency specialising in creating stunning digital experiences</HeroSubtitle>
        <ButtonContainer>
          <Button to="/portfolio">View Our Work</Button>
          <Button to="/contact">Get Started</Button>
        </ButtonContainer>
      </HeroSection>

      <ServicesSection>
        <ServicesContainer>
          <SectionTitle>Our Services</SectionTitle>
          <SectionSubtitle>We offer a range of services to help you achieve your digital goals.</SectionSubtitle>
          <StyledServices showFeatures={true} />
        </ServicesContainer>
      </ServicesSection>

      <ProcessSection aria-labelledby="process-title">
        <h2 id="process-title">Our Process</h2>
        <ProcessGrid
          variants={staggerContainerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          {process.map((step, index) => (
            <ProcessCard
              key={index}
              variants={cardVariants}
              layoutId={`process-${step.title}`}
              layout="position"
            >
              <ProcessIcon aria-hidden="true">
                <step.icon />
              </ProcessIcon>
              <ProcessTitle>{step.title}</ProcessTitle>
              <ProcessDescription>{step.description}</ProcessDescription>
            </ProcessCard>
          ))}
        </ProcessGrid>
      </ProcessSection>

      <ProjectsSection aria-labelledby="projects-title">
        <SectionTitle id="projects-title">Featured Projects</SectionTitle>
        <PortfolioPreview
          variants={staggerContainerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          {featuredProjects.map(project => (
            <ProjectCard
              key={project.id}
              variants={cardVariants}
              layoutId={`project-${project.id}`}
              layout="position"
            >
              <ProjectImage bgImage={project.image} aria-label={`${project.title} project thumbnail`} />
              <ProjectOverlay>
                <ProjectTitle>{project.title}</ProjectTitle>
                <ProjectCategory>{project.features.slice(0, 2).join(' • ')}</ProjectCategory>
              </ProjectOverlay>
            </ProjectCard>
          ))}
        </PortfolioPreview>
      </ProjectsSection>

      <TestimonialsSection aria-labelledby="testimonials-title">
        <h2 id="testimonials-title">What Our Clients Say</h2>
        <TestimonialsGrid
          variants={staggerContainerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              variants={cardVariants}
              layoutId={`testimonial-${testimonial.name}`}
              layout="position"
            >
              <blockquote>
                <TestimonialText>{testimonial.content}</TestimonialText>
                <TestimonialAuthor>
                  <AuthorImage aria-hidden="true" style={{ backgroundImage: `url(${testimonial.image})` }} />
                  <AuthorInfo>
                    <h4>{testimonial.name}</h4>
                    <p>{testimonial.role}</p>
                  </AuthorInfo>
                </TestimonialAuthor>
              </blockquote>
            </TestimonialCard>
          ))}
        </TestimonialsGrid>
      </TestimonialsSection>

      <CTASection aria-labelledby="cta-title">
        <h2 id="cta-title">Ready to Start Your Project?</h2>
        <p>Let's work together to create something amazing.</p>
        <ButtonContainer>
          <Button to="/contact" aria-label="Contact us to start your project">Get Started</Button>
        </ButtonContainer>
      </CTASection>
    </PageContainer>
  );
};

export default HomePage;
