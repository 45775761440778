interface TeamMember {
  id: number;
  name: string;
  role: string;
  description: string;
}

export const teamMembers: TeamMember[] = [
  {
    id: 1,
    name: "Damien Rozan",
    role: "Founder & Creative Director",
    description: "With a passion for innovative design and cutting-edge technology, Damien founded NeonPixel Designs to bridge the gap between creativity and functionality. His vision drives our commitment to delivering exceptional digital experiences."
  },
  {
    id: 2,
    name: "David Becker",
    role: "Web & Graphic Designer",
    description: "David creates beautiful websites and graphic designs that help organisations establish a strong brand identity and achieve their digital goals. His expertise spans both web and print design, ensuring consistent visual excellence across all platforms."
  },
  {
    id: 3,
    name: "Emily Parker",
    role: "Front-End Developer",
    description: "Emily specialises in creating responsive and interactive user interfaces, bringing designs to life with optimised code and smooth animations."
  }
];
