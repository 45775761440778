import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Navigate } from 'react-router-dom';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO/SEO';
import IsraeliPortfolio from '../components/Portfolio/IsraeliPortfolio';
import { isInIsrael } from '../utils/geolocation';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const IsraeliPortfolioPage: React.FC = () => {
  // Temporarily set to true for testing
  const [showIsraelOption, setShowIsraelOption] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // Temporarily comment out geolocation check for testing
  /*useEffect(() => {
    const checkLocation = async () => {
      const inIsrael = await isInIsrael();
      setShowIsraelOption(inIsrael);
      setIsLoading(false);
    };
    checkLocation();
  }, []);*/

  if (isLoading) {
    return null;
  }

  // Temporarily disable redirect for testing
  /*if (!showIsraelOption) {
    return <Navigate to="/portfolio" replace />;
  }*/

  return (
    <>
      <SEO
        title="Israeli Web Design Portfolio | Neon Pixel"
        description="Explore our portfolio of Israeli web design projects. See how we've helped local businesses establish their online presence with modern, responsive websites."
        keywords="israeli web design, hebrew websites, rtl web development, local web design, israel portfolio"
        url="/portfolio/israel"
        type="portfolio"
        noindex={true}
      />
      <PageHeader
        title="Israeli Portfolio"
        subtitle="Local projects with global standards"
      />
      <Container>
        <IsraeliPortfolio show={true} />
      </Container>
    </>
  );
};

export default IsraeliPortfolioPage;
