import { useEffect } from 'react';

export const useSupressResizeObserverError = () => {
  useEffect(() => {
    // Create new instance of the error handler
    const handler = (e: ErrorEvent) => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        e.stopImmediatePropagation();
      }
    };

    window.addEventListener('error', handler);

    return () => window.removeEventListener('error', handler);
  }, []);
};
