import { FaPalette } from 'react-icons/fa';

interface Service {
  title: string;
  description: string;
  icon: React.ComponentType;
  path: string;
  features?: string[];
}

const services: Service[] = [
  {
    title: 'Web Design',
    description: 'Custom web design solutions that combine stunning aesthetics with cutting-edge technology, crafted with modern frameworks and tools to deliver exceptional user experiences.',
    icon: FaPalette,
    path: '/services/web-design',
    features: [
      // Design
      'Modern & Responsive Design',
      'Interactive User Experience',
      'Custom Animations',
      'Brand Integration',
      'SEO Optimization & Best Practices',

      // Technology
      'React & Next.js',
      'Astro & TypeScript',
      'Tailwind CSS',
      'Performance Optimization'
    ]
  }
];

export default services;
