import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import SEO from '../components/SEO/SEO';
import { PageContainer } from '../components/Layout/PageContainer';

const HeroSection = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
  background: linear-gradient(135deg, #000014 0%, #001428 100%);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at center, rgba(255, 0, 255, 0.1) 0%, rgba(0, 255, 255, 0.1) 100%);
    pointer-events: none;
    z-index: 1;
  }
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  position: relative;
  z-index: 2;
`;

const Title = styled(motion.h1)`
  font-size: clamp(2rem, 5vw, 3.5rem);
  background: linear-gradient(to right, #ff00ff, #00ffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
  font-weight: 700;
`;

const Subtitle = styled(motion.h2)`
  font-size: clamp(1.2rem, 2.5vw, 1.8rem);
  color: ${props => props.theme.colors.text};
  margin-bottom: 2rem;
  opacity: 0.9;
`;

const FeatureList = styled(motion.ul)`
  list-style: none;
  padding: 0;
  margin: 2rem 0;
  text-align: left;
`;

const FeatureItem = styled(motion.li)`
  font-size: 1.2rem;
  margin: 1rem 0;
  padding-left: 2rem;
  position: relative;
  color: ${props => props.theme.colors.text};

  &::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: ${props => props.theme.colors.primary};
  }
`;

const Form = styled.form`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 2rem;
  position: relative;
`;

const InputWrapper = styled.div<{ isError?: boolean }>`
  position: relative;
  margin: 0.5rem 0;

  &::after {
    content: '*';
    color: #ff0055;
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.2rem;
    pointer-events: none;
    opacity: 0.8;
  }

  &.optional::after {
    display: none;
  }

  input {
    border-color: ${props => props.isError ? '#ff0055' : 'rgba(255, 255, 255, 0.2)'};
    ${props => props.isError && `
      box-shadow: 0 0 0 2px rgba(255, 0, 85, 0.2);
    `}
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
  transition: all 0.3s ease;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 1rem;
  margin: 0.5rem 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  color: ${props => props.theme.colors.text};
  font-size: 1rem;

  option {
    background: #001428;
    color: ${props => props.theme.colors.text};
  }
`;

const SubmitButton = styled(motion.button)`
  width: 100%;
  padding: 1rem 2rem;
  margin-top: 1rem;
  border: none;
  border-radius: 8px;
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-2px);
  }
`;

const Price = styled(motion.div)`
  font-size: 3rem;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  margin: 2rem 0;

  span {
    font-size: 1.5rem;
    opacity: 0.8;
  }
`;

const TermsSection = styled.section`
  padding: 4rem 2rem;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 2;
`;

const TermsTitle = styled.h2`
  font-size: 2rem;
  color: ${props => props.theme.colors.text};
  text-align: center;
  margin-bottom: 2rem;
  background: linear-gradient(to right, #ff00ff, #00ffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TermsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const TermsCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.primary};
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin: 0.5rem 0;
    padding-left: 1.5rem;
    position: relative;
    color: ${props => props.theme.colors.text};

    &::before {
      content: '•';
      position: absolute;
      left: 0;
      color: ${props => props.theme.colors.primary};
    }
  }
`;

const SuccessMessage = styled(motion.div)`
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid rgba(0, 255, 0, 0.2);
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  color: ${props => props.theme.colors.text};
  text-align: center;
`;

const LoadingOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  color: ${props => props.theme.colors.text};
`;

const OfferPage999: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const formRef = useRef<HTMLFormElement>(null);

  const validateForm = () => {
    if (!formRef.current) return false;
    const form = formRef.current;
    const inputs = Array.from(form.querySelectorAll('input[required]')) as HTMLInputElement[];
    let isValid = true;
    const newTouched: Record<string, boolean> = {};

    inputs.forEach((input) => {
      const name = input.getAttribute('name') || '';
      newTouched[name] = true;
      if (!input.value) {
        isValid = false;
      }
    });

    setTouched(newTouched);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const form = e.currentTarget;
      const response = await fetch(form.action, {
        method: 'POST',
        body: new FormData(form),
        headers: {
          Accept: 'application/json',
        },
      });

      if (response.ok) {
        setIsSubmitted(true);
        form.reset();
        setTouched({});
      } else {
        alert('Something went wrong. Please try again.');
      }
    } catch (error) {
      alert('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <PageContainer noPadding>
      <SEO
        title="Get a Professional Website for ₪999 | NeonPixel Designs"
        description="Limited time offer: Get a custom-built, fully optimized professional website for only ₪999. Modern, fast-loading, and designed to convert visitors into customers."
        keywords="website offer, professional website, web design offer, affordable website, business website"
        url="/999-offer"
        noindex={true}
      />
      <HeroSection>
        <ContentWrapper>
          <Title
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Get a High-Converting Professional Website
          </Title>
          <Subtitle
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Limited Time Offer
          </Subtitle>
          <Price
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            ₪999 <span>Only</span>
          </Price>
          <FeatureList
            initial="hidden"
            animate="visible"
            variants={{
              visible: {
                transition: {
                  staggerChildren: 0.1,
                  delayChildren: 0.6,
                }
              }
            }}
          >
            <FeatureItem
              variants={{
                hidden: { opacity: 0, x: -20 },
                visible: { opacity: 1, x: 0 }
              }}
            >
              Custom-Built & Fully Optimized
            </FeatureItem>
            <FeatureItem
              variants={{
                hidden: { opacity: 0, x: -20 },
                visible: { opacity: 1, x: 0 }
              }}
            >
              Modern, Sleek, and Fast-Loading (Bi-Lingual Options)
            </FeatureItem>
            <FeatureItem
              variants={{
                hidden: { opacity: 0, x: -20 },
                visible: { opacity: 1, x: 0 }
              }}
            >
              Designed to Convert Visitors into Customers
            </FeatureItem>
            <FeatureItem
              variants={{
                hidden: { opacity: 0, x: -20 },
                visible: { opacity: 1, x: 0 }
              }}
            >
              Your Business Deserves a Strong Online Presence!
            </FeatureItem>
          </FeatureList>

          <Form 
            ref={formRef}
            action="https://formspree.io/f/xovjozwp"
            method="POST"
            onSubmit={handleSubmit}
            style={{ position: 'relative' }}
          >
            {isSubmitting && (
              <LoadingOverlay
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                Sending...
              </LoadingOverlay>
            )}
            <InputWrapper isError={touched.name && !formRef.current?.querySelector<HTMLInputElement>('input[name="name"]')?.value}>
              <Input
                type="text"
                name="name"
                placeholder="Your Name"
                required
              />
            </InputWrapper>
            <InputWrapper isError={touched.email && !formRef.current?.querySelector<HTMLInputElement>('input[name="email"]')?.value}>
              <Input
                type="email"
                name="email"
                placeholder="Your Email"
                required
              />
            </InputWrapper>
            <InputWrapper isError={touched.phone && !formRef.current?.querySelector<HTMLInputElement>('input[name="phone"]')?.value}>
              <Input
                type="tel"
                name="phone"
                placeholder="Your Phone Number"
                required
              />
            </InputWrapper>
            <InputWrapper isError={touched.business && !formRef.current?.querySelector<HTMLInputElement>('input[name="business"]')?.value}>
              <Input
                type="text"
                name="business"
                placeholder="Business Name"
                required
              />
            </InputWrapper>
            <InputWrapper className="optional">
              <Input
                type="url"
                name="website"
                placeholder="Current Website URL (if any)"
              />
            </InputWrapper>
            <InputWrapper isError={touched.referralSource && !formRef.current?.querySelector<HTMLInputElement>('input[name="referralSource"]')?.value}>
              <Input
                type="text"
                name="referralSource"
                placeholder="Where did you see this offer?"
                required
              />
            </InputWrapper>
            <SubmitButton
              type="submit"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Start Your Project'}
            </SubmitButton>

            {isSubmitted && (
              <SuccessMessage
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
              >
                Thanks for your interest! We'll be in touch within 48 hours to discuss your project.
              </SuccessMessage>
            )}
          </Form>
        </ContentWrapper>
      </HeroSection>

      <TermsSection>
        <TermsTitle>Terms & Conditions</TermsTitle>
        <TermsGrid>
          <TermsCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h3>Website Types</h3>
            <ul>
              <li>Single page business website</li>
              <li>Portfolio website</li>
              <li>Landing page</li>
              <li>Simple business catalog</li>
              <li>Personal website</li>
            </ul>
          </TermsCard>

          <TermsCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <h3>Timeline</h3>
            <ul>
              <li>Project kickoff within 48 hours</li>
              <li>First iteration: 1 week</li>
              <li>Review and feedback period: 1 week</li>
              <li>Final iteration with updates: 1 week</li>
              <li>Total timeline: 3 weeks average</li>
              <li>Rush delivery available (additional fee)</li>
            </ul>
          </TermsCard>

          <TermsCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <h3>What's Included</h3>
            <ul>
              <li>Custom design based on your brand</li>
              <li>Mobile-responsive layout</li>
              <li>Up to 5 pages</li>
              <li>Contact form integration</li>
              <li>Basic SEO setup</li>
              <li>Google Analytics integration</li>
            </ul>
          </TermsCard>

          <TermsCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <h3>Content & Additional Services</h3>
            <ul>
              <li>Provide your own images and content</li>
              <li>Or we can suggest suitable content and imagery</li>
              <li>Domain registration (if needed)</li>
              <li>Additional pages beyond 5</li>
              <li>Custom functionality</li>
              <li>Premium stock photos (if required)</li>
            </ul>
          </TermsCard>
        </TermsGrid>
      </TermsSection>
    </PageContainer>
  );
};

export default OfferPage999;
