import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { blogPosts } from '../data/blogPosts';
import { PageContainer } from '../components/Layout/PageContainer';
import { ImageWithFallback } from '../components/Common/ImageWithFallback';

const PostContainer = styled(motion.article)`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const BackButton = styled(motion.button)`
  background: none;
  border: none;
  color: ${props => props.theme.colors.primary};
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
  padding: 0;

  &:hover {
    color: ${props => props.theme.colors.secondary};
  }
`;

const Header = styled.header`
  margin-bottom: 2rem;
`;

const Category = styled.span`
  color: ${props => props.theme.colors.primary};
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #ffffff;
  margin: 1rem 0;
  font-family: 'Outfit', sans-serif;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  color: #cccccc;
  margin-bottom: 1rem;
  font-weight: normal;
`;

const MetaInfo = styled.div`
  display: flex;
  gap: 2rem;
  color: #999999;
  font-size: 0.9rem;
  margin-bottom: 2rem;
`;

const StyledImage = styled(ImageWithFallback)`
  height: 400px;
  border-radius: 12px;
  margin-bottom: 2rem;
`;

const Content = styled.div`
  color: #ffffff;
  font-size: 1.1rem;
  line-height: 1.8;
  
  p {
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 1.8rem;
    color: ${props => props.theme.colors.primary};
    margin: 2rem 0 1rem;
  }

  h3 {
    font-size: 1.4rem;
    color: ${props => props.theme.colors.primary};
    margin: 1.5rem 0 1rem;
  }

  ul {
    margin: 1rem 0;
    padding-left: 1.5rem;

    li {
      margin-bottom: 0.5rem;
    }
  }
`;

const BlogPostPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const post = blogPosts.find(post => post.id === id);

  if (!post) {
    return (
      <PageContainer>
        <div>Post not found</div>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PostContainer
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <BackButton onClick={() => navigate(-1)}>
          ← Back to Blog
        </BackButton>
        
        <Header>
          <Category>{post.category}</Category>
          <Title>{post.title}</Title>
          <Subtitle>{post.subtitle}</Subtitle>
          <MetaInfo>
            <span>{post.date}</span>
            <span>•</span>
            <span>{post.author}</span>
            <span>•</span>
            <span>{post.readTime}</span>
          </MetaInfo>
        </Header>

        {post.image && (
          <StyledImage
            src={post.image}
            alt={post.imageAlt}
            height="400px"
            fallbackContent={
              <div>
                <h3>{post.title}</h3>
                <p>Image not available</p>
              </div>
            }
          />
        )}

        <Content>
          <ReactMarkdown>{post.content}</ReactMarkdown>
        </Content>
      </PostContainer>
    </PageContainer>
  );
};

export default BlogPostPage;
