import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import GlobalStyles from './styles/GlobalStyles';
import { HelmetProvider } from 'react-helmet-async';

const helmetContext = {};

// Suppress ResizeObserver loop error
const suppressResizeObserverError = () => {
  const resizeObserverError = "ResizeObserver loop completed with undelivered notifications.";
  const originalError = console.error;
  console.error = (...args) => {
    if (args.length > 0 && typeof args[0] === 'string' && args[0].includes(resizeObserverError)) {
      return;
    }
    originalError.apply(console, args);
  };
};

suppressResizeObserverError();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <GlobalStyles theme={theme} />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

// Log Helmet context after render
setTimeout(() => {
  console.log('Helmet Context:', helmetContext);
}, 1000);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
