import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background: ${props => props.theme.colors.background};
  padding: 1.5rem 2rem;
  border-top: 1px solid ${props => props.theme.colors.border};
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const LocationItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const LocationText = styled.p`
  color: ${props => props.theme.colors.textSecondary};
  font-size: 0.9rem;
`;

const EmailLink = styled.a`
  color: ${props => props.theme.colors.textSecondary};
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
  
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

const Links = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
  
  a {
    color: ${props => props.theme.colors.textSecondary};
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.2s ease;
    
    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

const Copyright = styled.p`
  color: ${props => props.theme.colors.textSecondary};
  font-size: 0.9rem;
  text-align: center;
`;

const locations = [
  {
    city: 'London, UK'
  },
  {
    city: 'Melbourne, Australia'
  },
  {
    city: 'Yavne, Israel'
  }
];

const LandingFooter: React.FC = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>Our Locations</h3>
          {locations.map((location, index) => (
            <LocationItem key={index}>
              <LocationText>{location.city}</LocationText>
            </LocationItem>
          ))}
        </FooterSection>
        <Links>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms">Terms of Service</Link>
        </Links>
        <Copyright>
          &copy; 2024 NeonPixel Designs. All rights reserved.
        </Copyright>
      </FooterContent>
    </FooterContainer>
  );
};

export default LandingFooter;
