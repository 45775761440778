import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaDesktop, FaMobile } from 'react-icons/fa';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO/SEO';
import { theme } from '../styles/theme';
import { portfolioImages } from '../assets/portfolio/images';
import ScreenshotModal from '../components/Modal/ScreenshotModal';
import { isInIsrael } from '../utils/geolocation';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0 4rem;
  flex-wrap: wrap;
  padding: 0 1rem;

  @media (max-width: 768px) {
    gap: 0.5rem;
    margin: 1rem 0 2rem;
  }
`;

const FilterButton = styled.button<{ active: boolean }>`
  background: ${props => props.active ? 
    `linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.secondary})` : 
    theme.colors.backgroundLight};
  border: 1px solid ${props => props.active ? 'transparent' : 'rgba(255, 255, 255, 0.1)'};
  color: ${theme.colors.text};
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;

  &:hover {
    background: ${props => !props.active && 'rgba(255, 255, 255, 0.1)'};
  }

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
`;

const ProjectGrid = styled.div`
  display: grid;
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 4rem; /* Add space before footer */
`;

const ProjectCard = styled(motion.div)`
  position: relative;
  width: 100%;
  background: ${props => props.theme.colors.backgroundLight};
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr;
  
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 100%;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
`;

const ProjectContent = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${props => props.theme.colors.backgroundLight};
`;

const ProjectCategory = styled.span`
  color: ${props => props.theme.colors.primary};
  font-size: 0.9rem;
  font-weight: 500;
`;

const ProjectTitle = styled.h3`
  color: ${props => props.theme.colors.text};
  font-size: 1.8rem;
  margin: 0;
  font-weight: 600;
`;

const ProjectDescription = styled.p`
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
  line-height: 1.6;
  opacity: 0.9;
  margin: 0;
`;

const TechStackSection = styled.div`
  margin-top: 1rem;
`;

const SectionTitle = styled.h4`
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
  opacity: 0.8;
`;

const TechList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const TechTag = styled.span`
  background: ${props => props.theme.colors.primary}15;
  color: ${props => props.theme.colors.primary};
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

const FeatureList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const FeatureTag = styled.span`
  background: ${props => props.theme.colors.secondary}15;
  color: ${props => props.theme.colors.secondary};
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.colors.primary};
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    color: ${props => props.theme.colors.secondary};
    transform: translateY(-2px);
  }

  span {
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

const ScreenshotButtons = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;
  padding: 0.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

interface Project {
  id: number;
  title: string;
  description: string;
  image: string;
  category: string;
  features: string[];
  techStack: string[];
  link?: string;
  desktopScreenshot?: string;
  mobileScreenshot?: string;
  region?: 'israel' | 'global';
}

const Project: React.FC<{ project: Project }> = ({ project }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const handleScreenshotClick = (imageUrl: string) => {
    setCurrentImage(imageUrl);
    setShowModal(true);
  };

  return (
    <>
      <ProjectCard
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
      >
        <ImageContainer>
          <ProjectImage
            src={project.image}
            alt={`${project.title} - ${project.description}`}
            loading="eager"
            width="600"
            height="400"
            decoding="async"
            fetchPriority="high"
          />
        </ImageContainer>
        <ProjectContent>
          <ProjectCategory role="text" aria-label="Project category">
            {project.category}
          </ProjectCategory>
          <ProjectTitle 
            id={`project-title-${project.id}`}
            role="heading" 
            aria-level={3}
          >
            {project.title}
          </ProjectTitle>
          <ProjectDescription role="text">
            {project.description}
          </ProjectDescription>
          {project.techStack && (
            <TechStackSection>
              <SectionTitle>Tech Stack</SectionTitle>
              <TechList role="list" aria-label="Technologies used">
                {project.techStack.map((tech: string, index: number) => (
                  <TechTag key={index} role="listitem">{tech}</TechTag>
                ))}
              </TechList>
            </TechStackSection>
          )}
          {project.features && (
            <div>
              <SectionTitle>Key Features</SectionTitle>
              <FeatureList role="list" aria-label="Project features">
                {project.features.map((feature: string, index: number) => (
                  <FeatureTag key={index} role="listitem">{feature}</FeatureTag>
                ))}
              </FeatureList>
            </div>
          )}
        </ProjectContent>
        <ScreenshotButtons>
          {project.desktopScreenshot && (
            <IconButton 
              onClick={() => handleScreenshotClick(project.desktopScreenshot!)}
              aria-label="View desktop screenshot"
            >
              <FaDesktop />
              <span>Desktop</span>
            </IconButton>
          )}
          {project.mobileScreenshot && (
            <IconButton 
              onClick={() => handleScreenshotClick(project.mobileScreenshot!)}
              aria-label="View mobile screenshot"
            >
              <FaMobile />
              <span>Mobile</span>
            </IconButton>
          )}
        </ScreenshotButtons>
      </ProjectCard>
      <ScreenshotModal
        isOpen={showModal}
        imageUrl={currentImage}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

const projects: Project[] = [
  {
    id: 1,
    title: "Jada Trading",
    description: "A modern website for a premium toy distribution company, connecting businesses worldwide through elegant design and seamless user experience.",
    image: portfolioImages.jadaTrading,
    category: "Web Development",
    features: [
      "Modern UI Design",
      "Optimized Performance",
      "Responsive Layout",
      "Interactive Elements",
      "SEO Best Practices"
    ],
    techStack: [
      "Next.js 13 (App Router)",
      "TypeScript",
      "Tailwind CSS",
      "shadcn/ui",
      "Radix UI",
      "Lucide React"
    ],
    desktopScreenshot: "/images/images-biz/Jada-desktop.png",
    mobileScreenshot: "/images/images-biz/Jada-mobile.png"
  },
  {
    id: 2,
    title: "Natali.ai",
    description: "A dynamic marketing automation business website showcasing AI-powered solutions that help companies streamline their marketing efforts and boost engagement.",
    image: portfolioImages.nataliAi,
    category: "Web Development",
    features: [
      "Modern UI Design",
      "Smooth Animations",
      "Responsive Layout",
      "API Integration",
      "SEO Best Practices",
      "Optimized Performance"
    ],
    techStack: [
      "React",
      "Tailwind CSS",
      "Framer Motion",
      "Vite",
      "JavaScript"
    ],
    desktopScreenshot: "/images/images-biz/natali-desktop.png",
    mobileScreenshot: "/images/images-biz/natali-mobile.png"
  },
  {
    id: 3,
    title: "S2 Management",
    description: "A modern website for an AI-powered CCTV monitoring service, providing remote security solutions through efficient technology.",
    image: portfolioImages.s2Management,
    category: "Web Development",
    features: [
      "Modern UI Design",
      "Responsive Layout",
      "Smooth Animations",
      "SEO Optimization",
      "Performance Focused"
    ],
    techStack: [
      "Next.js",
      "TypeScript",
      "Tailwind CSS",
      "Framer Motion"
    ],
    desktopScreenshot: "/images/images-biz/s2-desktop.png",
    mobileScreenshot: "/images/images-biz/s2-mobile.png"
  },
  {
    id: 4,
    title: "CommSearch",
    description: "A consultancy platform helping businesses make informed decisions about communication solutions through tailored recommendations and expert insights.",
    image: portfolioImages.commSearch,
    category: "Web Development",
    features: [
      "Modern Design",
      "Interactive UI",
      "Type Safety",
      "Smooth Animations",
      "SEO Optimization"
    ],
    techStack: [
      "Astro",
      "TypeScript",
      "Tailwind CSS",
      "Motion",
      "Alpine.js"
    ],
    desktopScreenshot: "/images/images-biz/commsearch-desktop.png",
    mobileScreenshot: "/images/images-biz/commsearch-mobile.png"
  },
  {
    id: 5,
    title: "Brody Plumbing & Heating",
    description: "A professional website for a trusted Gas Safe registered plumbing and heating service, focusing on reliability and customer satisfaction.",
    image: portfolioImages.brodyPlumbing,
    category: "Web Development",
    features: [
      "Performance Optimized",
      "Smooth Transitions",
      "Image Optimization",
      "SEO Optimization",
      "Modern Typography"
    ],
    techStack: [
      "Astro",
      "TypeScript",
      "Tailwind CSS"
    ],
    desktopScreenshot: "/images/images-biz/brody-desktop.png",
    mobileScreenshot: "/images/images-biz/brody-mobile.png"
  },
  {
    id: 6,
    title: "Quattro Hair Fashion",
    description: "An elegant website for a premier hair salon, featuring online booking integration and a stunning portfolio of their work.",
    image: portfolioImages.quattroHair,
    category: "Web Development",
    features: [
      "Modern Design",
      "Online Booking",
      "Service Gallery",
      "Location Integration",
      "SEO Optimization",
      "Performance Focused"
    ],
    techStack: [
      "Astro",
      "Tailwind CSS",
      "JavaScript",
      "Booking API"
      
    ],
    desktopScreenshot: "/images/images-biz/quattro-desktop.png",
    mobileScreenshot: "/images/images-biz/quattro-mobile.png"
  },
  {
    id: 20,
    title: "Grossman Tennis",
    description: "A dynamic marketing automation business website showcasing AI-powered solutions that help companies streamline their marketing efforts and boost engagement.",
    image: portfolioImages.grossmanTennis,
    category: "Web Development",
    region: 'israel',
    features: [
      "Multilingual Support",
      "SEO Optimization",
      "Image Optimization",
      "Performance Optimized",
      "Responsive Design",
      "Modern UI/UX"
    ],
    techStack: [
      "Astro",
      "TailwindCSS",
      "Autoprefixer"
    ],
    desktopScreenshot: "/images/images-biz/grossman-desktop2.png",
    mobileScreenshot: "/images/images-biz/grossman-mobile.png"
  },
  {
    id: 21,
    title: "Sailing Israel",
    description: "A comprehensive sailing school and yacht charter website offering ASA-certified courses and curated Mediterranean sailing experiences from Herzliya Marina.",
    image: portfolioImages.sailingIsrael,
    category: "Web Development",
    region: 'israel',
    features: [
      "Custom Animations",
      "Interactive Hover States",
      "Performance Optimized",
      "SEO Optimization",
      "Modern UI/UX"
    ],
    techStack: [
      "Astro",
      "Vanilla CSS",
      "TypeScript",
      "Lucide icons"
    ],
    desktopScreenshot: "/images/images-biz/sail-desktop.png",
    mobileScreenshot: "/images/images-biz/sail-mobile.png"
  },
  {
    id: 22,
    title: "The Dog Father",
    description: "A professional dog grooming website offering specialized services for large breeds in Tel Aviv, including bathing, grooming, nail trimming, and ear cleaning.",
    image: portfolioImages.dogFather,
    category: "Web Development",
    region: 'israel',
    features: [
      "Modern UI Design",
      "Optimized Performance",
      "Responsive Layout",
      "SEO Best Practices",
      "Modern Design"
    ],
    techStack: [
      "Astro",
      "TailwindCSS",
      "TypeScript",
      "Vite"
    ],
    desktopScreenshot: "/images/images-biz/dog-desktop.png",
    mobileScreenshot: "/images/images-biz/dog-mobile.png"
  }
];

const PortfolioPage: React.FC = () => {
  const [showIsraelProjects, setShowIsraelProjects] = useState(false);
  const [showIsraelOption, setShowIsraelOption] = useState(false);

  useEffect(() => {
    // Suppress ResizeObserver loop error in development
    const resizeObserverError = window.console.error;
    window.console.error = (...args: any) => {
      if (args[0]?.includes?.('ResizeObserver loop')) return;
      resizeObserverError(...args);
    };

    return () => {
      window.console.error = resizeObserverError;
    };
  }, []);

  useEffect(() => {
    const checkLocation = async () => {
      const inIsrael = await isInIsrael();
      setShowIsraelOption(inIsrael);
    };
    checkLocation();
  }, []);

  const filteredProjects = projects.filter(project => {
    if (!showIsraelOption) {
      return !project.region || project.region === 'global';
    }
    if (showIsraelProjects) {
      return project.region === 'israel';
    }
    return true;
  });

  return (
    <>
      <SEO 
        title="Web Design Portfolio"
        description="Explore our portfolio of stunning web designs, custom websites, and digital solutions. See how we've helped businesses transform their online presence."
        keywords="web design portfolio, website examples, web development projects, UI design portfolio, digital agency work"
        url="/portfolio"
        type="portfolio"
      />
      <PageHeader
        title="Our Portfolio"
        subtitle="Transforming ideas into digital experiences"
      />
      <Container>
        {showIsraelOption && (
          <FilterContainer>
            <FilterButton
              active={!showIsraelProjects}
              onClick={() => setShowIsraelProjects(false)}
            >
              All Projects
            </FilterButton>
            <FilterButton
              active={showIsraelProjects}
              onClick={() => setShowIsraelProjects(true)}
            >
              Israeli Projects
            </FilterButton>
          </FilterContainer>
        )}
        <ProjectGrid
          role="feed"
          aria-label="Portfolio projects"
        >
          <AnimatePresence>
            {filteredProjects.map((project) => (
              <Project key={project.id} project={project} />
            ))}
          </AnimatePresence>
        </ProjectGrid>
      </Container>
    </>
  );
};

export default PortfolioPage;
